import React from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getDesprecio, CurrencyFormatter } from "app/components/helpers/Views";
import { toast } from "react-toastify";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "react-redux";
import { useEffect, useState } from "react";
import { safeReplace } from 'app/utils/utils';

const circleBadgeStyle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#172983", // Puedes cambiar el color según tus preferencias
  color: "#fff",
  textAlign: "center",
  lineHeight: "30px",
};

export const StepContratado = ({ planHistorico, bajaFuncion, modificarFuncion, plan }) => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  let [textoVecinos, setTextoVecinos] = useState("");

  const handleOpenPdf = () => {
    const fileUrl = "/assets/files/tabla_de_precios.pdf"; // Reemplaza con la ruta correcta de tu archivo
    const newWindow = window.open(fileUrl, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      toast.error(
        diccionario.ErrorAbrirNuevaVentanaPermisosVentanas,
        getCustomToastOptions(toast, "error")
      );
    }
  };

  useEffect(() => {
    setTextoVecinos(
      diccionario.preciosPorVecinoHistorico
        ? safeReplace(diccionario.preciosPorVecinoHistorico, "${var1}", planHistorico.vecinos)
        : planHistorico.vecinos
    );    
  }, [planHistorico]);

  return (
    <div className="alta365">
      <div className="container mt-4">
        <div className="d-flex align-items-center contrato">
          <div style={circleBadgeStyle} className="mr-2">
            1
          </div>

          <h5 style={{ marginLeft: "15px", flex: "1", marginBottom: "0px" }}>
            {diccionario.planContratadoActivo}
          </h5>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button variant="outline-primary" onClick={modificarFuncion}>
              {diccionario.actualizarModificarPack}
            </Button>
            <Button variant="outline-danger" onClick={bajaFuncion}>
              {diccionario.darDeBaja}
            </Button>
            <Button variant="outline-primary" onClick={handleOpenPdf}>
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: "5px" }} />
              {diccionario.descargarPrecios}
            </Button>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="alta365-planes">
        <Card style={{ width: "28rem" }}>
          <Card.Body>
            <Card.Title>
              {diccionario.Plan}: {planHistorico.plan.charAt(0).toUpperCase() + planHistorico.plan.slice(1)}
            </Card.Title>

            <hr></hr>
            <Card.Text>
              {" "}
              {diccionario.iban}: {planHistorico.iban.charAt(0).toUpperCase() + planHistorico.iban.slice(1)}
            </Card.Text>
            <Card.Text> {diccionario.numeroIban}: {planHistorico.cuentaBancaria}</Card.Text>
            <Card.Text> {diccionario.Vecinos}: {planHistorico.vecinos}</Card.Text>
            <Card.Text>
              {" "}
              {diccionario.Periodicidad}:{" "}
              {planHistorico.formapago.charAt(0).toUpperCase() + planHistorico.formapago.slice(1)}
            </Card.Text>
            <Card.Text> {diccionario.Precio}: {planHistorico.precio}€</Card.Text>
          </Card.Body>
        </Card>
      </div>
      <i>
        <h6 style={{ marginTop: "15px" }}>* {textoVecinos}</h6>
      </i>
      <i>
        <h6>* {diccionario.preciosSinIva}</h6>
      </i>
    </div>
  );
};

export default StepContratado;
