import { useEffect, useState, useRef } from "react";
import { get, orderByChild, equalTo, query, update, set, push } from "firebase/database";
import { TextField } from "@mui/material";
import * as ROUTES from "../../constants/routes";
import { normalizeString } from "../../components/helpers/Various";
import useAuth from "app/hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { useStore } from "react-redux";
import { useFirebase } from "../../../app/firebase/context";
//import { UseLenguageState } from 'app/contexts/LenguageContext';
import React from "react";
import Grid from "@mui/material/Grid";
import "font-awesome/css/font-awesome.min.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { format } from "date-fns";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ModalComponent from "app/components/ModalComponent/ModalComponent";
import FormularioEnvioAvisos from "app/components/ModalComponent/FormularioEnvioAvisos";
import BackButton from "app/components/helpers/BackButton";
import { RefreshButton } from "app/components/helpers/Refresh";
import { Dialogo } from "app/components/helpers/Dialogo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ExportToCSV, ExportToPDF } from "app/library/ExportCsvPdf";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import Wizard from "../wizard/Wizard";
import DatePicker from "@mui/lab/DatePicker";
import { Checkbox } from "@material-ui/core";
import { PiArrowElbowLeftDownBold } from "react-icons/pi";
import { Form } from "react-bootstrap";
import Step4 from "../wizard/Step4";
import StepContratado from "../wizard/StepContratado";
import ClipLoader from "react-spinners/ClipLoader";

import { getFunctions, httpsCallable } from "firebase/functions";
import { getSemaforoComunidad } from "./FuncionesComunidades";
import { safeReplace } from "app/utils/utils";

const getStatusActiva = (event, datosComunidad, diccionario) => {
  const activa = datosComunidad.Alta && datosComunidad.Alta ? datosComunidad.Alta : false;
  return activa ? (
    <Tooltip content={diccionario.tip_Baja}>
      <CheckCircleIcon style={{ color: "green" }} />
    </Tooltip>
  ) : (
    <Tooltip content={diccionario.tip_Alta}>
      <CancelIcon style={{ color: "grey" }} />
    </Tooltip>
  );
};

const getBloqueada = (e, datosComunidad, diccionario) => {
  let bloqueada = false;
  if (datosComunidad.bloqueada && datosComunidad.bloqueada) {
    bloqueada = datosComunidad.bloqueada;
  }
  return bloqueada ? (
    <Tooltip content={diccionario.tip_Bloqueada}>
      <LockIcon color="primary" />
    </Tooltip>
  ) : (
    <Tooltip content={diccionario.tip_Bloqueada}>
      <LockOpenIcon color="primary" />
    </Tooltip>
  );
};

const ComunidadesTabla = () => {
  const currentDate = new Date().toISOString().split("T")[0];

  //const { state, dispatch } = UseLenguageState();
  //const [selectedOption, setSelectedOption] = useState('');
  const [esModificar, setEsModificar] = useState(false);
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [filtradosRefresh, setFiltradosRefresh] = useState(); //let [diccionario, setDiccionario] = useState(lengu.diccionario);
  const location = useLocation();
  let [comunidadeslista, setComunidadeslista] = useState([]);
  let [comunidadesSeleccionadas, setComunidadesSeleccionadas] = useState([]);
  let [numRtdos, setNumRtdos] = useState([]);
  let [cuerpoBloqueo, setCuerpoBloqueo] = useState("");
  let [tituloBloqueo, setTituloBloqueo] = useState("");
  const datosIniciales = useRef(comunidadeslista);
  const { user } = useAuth();
  //const modificar = user.role == authRoles.sa;
  const [isModalOpenAlta, setModalOpenAlta] = useState(false);
  const [step, setStep] = useState(1);
  const listaFiltros = ["Nombre", "Id", "Cif", "Direccion"];
  const [Identificador, setIdentificador] = useState("");
  const [IdentificadorPlan, setIdentificadorPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [datosCompletosSeleccionados, setDatosCompletosSeleccionados] = useState(null);
  const [planHistorico, setPlanHistorico] = useState({
    fechainicio: "",
    plan: "",
    formapago: "",
    iban: "",
    nombre: "",
    vecinos: "",
    tablaPrecios: null,
    precioSelected: null,
    id: "",
  });

  const [plan, setPlan] = useState({
    plan: "",
    formapago: "",
    iban: "",
    nombre: "",
    vecinos: "",
    tablaPrecios: null,
    precioSelected: null,
    modificar: false,
    cuentaBancaria: "",
  });

  const [modalOpenContratado, setModalOpenContratado] = useState(false);
  const refresh = () => {
    handleFiltrarClick();
    toast.info(diccionario.datosRefrescados, getCustomToastOptions(toast, "info"));
  };

  const [refrescar, setRefrescar] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogAlta, setShowDialogAlta] = useState(false);

  const [showDialogForm, setshowDialogForm] = useState(false);
  const [rowDialog, setRowDialog] = useState(null);

  const confirmarBloqueo = () => {
    bloquearComunidad().then(() => {
      setShowDialog(false);
      setRefrescar(!refrescar);
    });
  };

  const bloquearComunidad = async () => {
    try {
      const comunidadRef = firebase.getReferencia("Comunidad");
      const idComunidad = rowDialog.Id;

      // Obtener la información actualizada después de bloquear/desbloquear
      const snapshot = await get(query(comunidadRef, orderByChild("Id"), equalTo(idComunidad)));

      const itemId = Object.keys(snapshot.val())[0];

      // Actualizar el estado con la información obtenida
      const updatedData = {
        ...snapshot.val()[itemId],
        bloqueada: !rowDialog.bloqueada,
      };
      const comUpdatear = firebase.getReferencia(`Comunidad/${itemId}`);
      await update(comUpdatear, updatedData);
      toast.success(diccionario.DatosGuardadosCorrectamente, getCustomToastOptions(toast));
    } catch (error) {
      console.error("Error al bloquear/desbloquear comunidad", error);
    }
  };
  const abrirDiagoloBaja = (e, row) => {
    {
      /* e.stopPropagation();
    setIdentificador(row.idInterno);
  setShowDialogAlta(true);*/
    }

    /* 
    setIdentificador(row.idInterno);
    setShowDialogAlta(true);*/
    e.stopPropagation();
    obtenerPlan(row.idInterno);
  };
  const darDeBaja = () => {
    setShowDialogAlta(true);
  };
  const handleBloqueadaClick = (e, row) => {
    const bloqueada = row.bloqueada;
    const titulo = bloqueada ? diccionario.tituloDesBloquear : diccionario.tituloBloquear;
    const cuerpo = bloqueada ? diccionario.cuerpoDesBloquear : diccionario.cuerpoBloquear;
    setCuerpoBloqueo(cuerpo);
    setTituloBloqueo(titulo);
    setShowDialog(true);
    setRowDialog(row);
    e.stopPropagation(); //
  };

  const handleAltaC365 = (e, row) => {
    e.stopPropagation();
    if (row.Alta !== true) {
      setPlanHistorico({
        ...planHistorico,
        plan: "",
        formapago: "",
        iban: "",
      });
      setModalOpenAlta(true);
      setDatosCompletosSeleccionados(row);
      setPlan((prevPlan) => ({
        ...prevPlan,
        vecinos: row.Propiedades,
        idInterno: row.idInterno,
      }));
      setIdentificador(row.idInterno);

      console.log("El plantario", plan);
    }
  };

  const obtenerPlan = async (row) => {
    const snapshot = await get(firebase.getReferencia(`Comunidad/${row}`, null));
    let datetes = snapshot.val().ContratoActivo;
    datetes.IBAN = snapshot.val().IBAN;

    setModalOpenContratado(true);
    setPlanHistorico({
      plan: datetes.plan,
      fechaInicio: datetes.fechaInicio,
      iban: datetes.iban,
      precio: datetes.precioSelected,
      vecinos: datetes.vecinos,
      formapago: datetes.formapago,
      id: row,
      cuentaBancaria: datetes.cuentaBancaria,
    });

    setEsModificar(true);
    const datosComu = snapshot.val();

    /* Lo comento porque no sabemos si usar el campo de vecinos o el de instancias
    let numViviendas = 0;
    if (datosComu.Instancias) {
      numViviendas = datosComu.Instancias.filter((instancia) => instancia.Usuario != "").length;
    }
    datosComu.Vecinos = numViviendas;
*/
    setDatosCompletosSeleccionados(datetes);
    setPlan((prevPlan) => ({
      ...prevPlan,
      vecinos: datosComu.Propiedades,
      idInterno: datosComu.Id,
      modificar: true,
    }));
    setIdentificador(datosComu.Id);
  };

  const handleModificarC365 = async () => {
    setModalOpenAlta(true);
  };

  const handleBajaC365 = async (e) => {
    e.stopPropagation();
    setModalOpenContratado(false);
    setShowDialogAlta(false);
    setLoading(true);
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("es-ES", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    try {
      const updatedData = {
        Alta: false,
        fechaBaja: formattedDate,
        ContratoActivo: {
          fechaInicio: "",
          fechaFin: "",
          formapago: "",
          iban: "", // MIRAR SI HABRÍA QUE GUARDAR EL IBAN EN EL MOMENTO DE CONTRATAR (SEGURAMENTE SI, Y TENER EN CUENTA )
          plan: "",
          precioSelected: "",
          vecinos: "",
        },
      };
      const datosHistoricos = {
        fechaInicio: planHistorico.fechaInicio,
        fechaFin: formattedDate,
        formapago: planHistorico.formapago,
        iban: planHistorico.iban, // MIRAR SI HABRÍA QUE GUARDAR EL IBAN EN EL MOMENTO DE CONTRATAR (SEGURAMENTE SI, Y TENER EN CUENTA )
        plan: planHistorico.plan,
        precioSelected: planHistorico.precio,
        vecinos: planHistorico.vecinos,
      };

      const comUpdatear11 = firebase.getReferencia(`Comunidad/${planHistorico.id}`);

      const datosHistoricosFirebase = firebase.getReferencia(
        `Comunidad/${planHistorico.id}/HistoricoContratos`
      );

      const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
      var stopCommunityPayment = httpsCallable(functions, "stopCommunityPayment");
      const deskId = user.despacho.Codigo;

      const comBaja = comunidadeslista.filter((com) => com.idInterno === planHistorico.id);

      const typeIban = comBaja[0].ContratoActivo ? comBaja[0].ContratoActivo.iban : null;

      const type = typeIban === "administrador" ? "Despacho" : "Comunidad";

      stopCommunityPayment({
        deskId: deskId,
        communityId: planHistorico.id,
        type: type,
      })
        .then(async (res) => {
          await update(comUpdatear11, updatedData);
          await push(datosHistoricosFirebase, datosHistoricos);
          setShowDialogAlta(false);
          setRefrescar(!refrescar);
          toast.success(diccionario.AltaCorrecta, getCustomToastOptions(toast));
          setLoading(false);
        })
        .catch((e) => {
          toast.error(diccionario.FalloBajaComunidad, getCustomToastOptions(toast, "error"));
          setLoading(false);
          console.log("Envío fallido", e);
        });
    } catch (error) {
      toast.error(diccionario.FalloBajaComunidad, getCustomToastOptions(toast, "error"));
      setLoading(false);
      console.error("Error al bloquear/desbloquear comunidad", error);
    }
  };

  const columnas = [
    {
      text: diccionario.estadoComunidad,
      dataField: "Semaforito",
      cellStyle: { textAlign: "center" },
      filtering: false,
      formatter: (e, rowData) => {
        return getSemaforoComunidad(rowData, diccionario);
      },
      headerClasses: "semoforito-header",
    },
    {
      text: diccionario.Altac365,
      dataField: "Alta",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          row.Alta == true ? abrirDiagoloBaja(e, row) : handleAltaC365(e, row);
        },
      },
      cellStyle: { textAlign: "center" },
      formatter: (e, rowData) => {
        return getStatusActiva(e, rowData, diccionario);
      },
    },
    {
      text: diccionario.nombre,
      dataField: "Nombre",
      sort: true,
      align: "left",
      headerAlign: "left",
    },

    { text: diccionario.Cif, dataField: "Cif", sort: true },
    {
      text: diccionario.Direccion,
      dataField: "Direccion",
      sort: true,
      align: "left",
      headerAlign: "left",
    },
    {
      text: diccionario.Poblacion,
      dataField: "Poblacion",
      sort: true,
      align: "left",
      headerAlign: "left",
    },
    {
      text: diccionario.Provincia,
      dataField: "Provincia",
      sort: true,
      align: "left",
      headerAlign: "left",
    },
    { text: diccionario.Cp, dataField: "Cp", sort: true },
    { text: diccionario.Propiedades, dataField: "Propiedades", sort: true },
    { text: diccionario.Vecinos, dataField: "Vecinos", sort: true },
    {
      text: diccionario.Fecha_Creacion,
      dataField: "fechaAlta",
      sort: true,
      align: "center",
    },
    {
      text: diccionario.Fecha_Baja,
      dataField: "fechaBaja",
      sort: true,
      align: "center",
      formatter: (e, rowData) => {
        if (rowData.Alta === true) {
          return "";
        } else {
          return formateoFechaBaja(e, rowData);
        }
      },
    },
    {
      text: diccionario.Bloqueada,
      dataField: "Bloqueada",
      cellStyle: { textAlign: "center" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => handleBloqueadaClick(e, row),
      },
      formatter: (e, rowData) => {
        return getBloqueada(e, rowData, diccionario);
      },
    },
  ];
  const tituloTabla = diccionario.listaComunidades;
  const tableLocation = lengu.traduccionTabla;
  const routeDetalle = ROUTES.DETALLE_COMUNIDAD;
  const firebase = useFirebase();

  const formateoFechaAlta = (e, rowData) => {
    if (!rowData.fechaAlta) {
      return "";
    } else {
      const fechaGorda = rowData.fechaAlta.split(",");

      const partesFecha = fechaGorda[0].split("/");

      // Obtener el día y el mes
      let dia = partesFecha[0];
      let mes = partesFecha[1];
      const año = partesFecha[2];

      // Asegurarse de que el día y el mes tengan dos dígitos
      dia = dia.padStart(2, "0");
      mes = mes.padStart(2, "0");
      const fechaFormateada = `${dia}/${mes}/${año}`;
      // Formar la nueva fecha en el formato deseado
      return <div>{fechaFormateada}</div>;
    }
  };

  const formateoFechaBaja = (e, rowData) => {
    if (!rowData.fechaBaja) {
      return "";
    } else if (rowData.Alta == true) {
      return "";
    } else {
      const fechaGorda = rowData.fechaBaja.split(",");

      const partesFecha = fechaGorda[0].split("/");

      // Obtener el día y el mes
      let dia = partesFecha[0];
      let mes = partesFecha[1];
      const año = partesFecha[2];

      // Asegurarse de que el día y el mes tengan dos dígitos
      dia = dia.padStart(2, "0");
      mes = mes.padStart(2, "0");
      const fechaFormateada = `${dia}/${mes}/${año}`;
      // Formar la nueva fecha en el formato deseado
      return <div>{fechaFormateada}</div>;
    }
  };

  const handleAllRowSelect = (isSelect, rows) => {
    if (isSelect) {
      // Filtrar solo las comunidades de alta
      const comunidadesAlta = comunidadeslista.filter((comunidad) => comunidad.Alta);

      setComunidadesSeleccionadas(comunidadesAlta);
    } else {
      setComunidadesSeleccionadas([]);
    }
  };

  const handleOnSelect = (row, isSelected, rowIndex, e) => {
    if (isSelected) {
      // If the row is selected, add it to the array
      setComunidadesSeleccionadas((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      // If the row is deselected, remove it from the array
      setComunidadesSeleccionadas((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow.Cif !== row.Cif)
      );
    }
  };
  const navigate = useNavigate();

  let options = {
    custom: true,
    paginationSize: 5,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
    ],
    totalSize: numRtdos,
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      row.modalAltaC365 = false;

      if (row.Alta === true) {
        navigate(routeDetalle + "/" + row.idInterno, { state: { ...row } });
      } else {
        toast.info(diccionario.ComunidadNoAlta, getCustomToastOptions(toast, "info"));
      }
    },
  };

  const cargarComunidades = async () => {
    let comunidades = [];
    if (!user.SuperAdmin) {
      const snapshot5 = await get(
        firebase.getReferencia(`Usuarios/${firebase.auth.currentUser.uid}`, null)
      );

      snapshot5.val().Comunidades &&
        Object.keys(snapshot5.val().Comunidades).forEach((comunidad) => {
          if (comunidad !== "desk") {
            comunidades.push(comunidad);
          }
        });
    }

    setLoading(true);
    try {
      const snapshot2 = await get(firebase.getReferencia("Despacho", null));
      let ibanDespacho = snapshot2.val().Iban ? snapshot2.val().Iban : "";
      const snapshot = await get(firebase.getReferencia("Comunidad", null));
      let datos = [];
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        childData.idInterno = childKey;
        if (!childData.Borrado) {
          let numViviendas = 0;
          if (childData.Instancias) {
            numViviendas = childData.Instancias.filter(
              (instancia) => instancia.Usuario != ""
            ).length;
          }
          childData.ibanDespacho = ibanDespacho;
          childData.Vecinos = numViviendas;
          if (comunidades.includes(childKey) || comunidades.length === 0) {
            datos.push(childData);
          }
        }
      });
      console.log("datos", datos);
      setComunidadeslista(datos);
      setNumRtdos(datos.length);
      datosIniciales.current = datos;
    } catch (error) {
      console.error("Error al cargar comunidades", error);
    }
    setLoading(false);
  };

  const cargarComunidadesFiltros = async () => {
    try {
      const snapshot = await get(firebase.getReferencia("Comunidad", null));
      let datos = [];
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        childData.idInterno = childKey;
        datos.push(childData);
      });
      setComunidadeslista(datos);
      setNumRtdos(datos.length);
    } catch (error) {
      console.error("Error al cargar comunidades", error);
    }
  };
  useEffect(() => {
    if (location.state) {
      const loc = location.state;
      console.log("loc", loc);
      setComunidadeslista(loc.listaComus);
      setNumRtdos(loc.listaComus.length);
      datosIniciales.current = loc.listaComus;
    } else {
      cargarComunidades();
    }
  }, [refrescar]);

  //// COSAS DEL FORM DE BUSCAR
  /* utilizo la referencia para guardar los empleados iniciales, asi cuando se resetea el filtro vuelvo a mostrar todos los empleados */

  /* objeto que guarda los valores de los inputs */
  const [expanded, setExpanded] = useState(true);

  /* objeto que guarda los valores de los inputs */
  const filterObject = {};

  for (const field of listaFiltros) {
    filterObject[field] = "";
  }

  const estadoInicialFiltros = {
    Id: "",
    Nombre: "",
    Cif: "",
    Poblacion: "",
    Cp: "",
    Vecinos: "",
    Propiedades: "",
    Fecha_Creacion: "",
    Provincia: "",
    Direccion: "",
    Alta: "",
    fechaAlta: "",
    fechaInicio: "",
    fechaFin: "",
  };
  const [filter, setFilter] = useState(estadoInicialFiltros);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFiltrarClick(e);
    }
  };
  const handleFiltrarClick = async (e) => {
    let datos = [];
    try {
      const snapshot = await get(firebase.getReferencia("Comunidad", null));

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        childData.idInterno = childKey;
        if (!childData.Borrado) {
          let numViviendas = 0;
          if (childData.Instancias) {
            numViviendas = childData.Instancias.filter(
              (instancia) => instancia.Usuario != ""
            ).length;
          }
          childData.Vecinos = numViviendas;
          datos.push(childData);
        }
      });
    } catch (error) {
      console.error("Error al cargar comunidades", error);
    }

    /*
    datos.map((dato) => {
      if (dato.fechaAlta) {
        const fechaFormateada = dato.fechaAlta.split('-').reverse().join('-').slice(0, 8);
      }
    }); */
    e && e.preventDefault();

    try {
      //if (comunidadeslista.length > 0) {
      let filtrados = [];
      if (
        !(
          filter.Id === "" &&
          filter.Nombre === "" &&
          filter.Cif === "" &&
          filter.Poblacion === "" &&
          filter.Cp === "" &&
          filter.Vecinos === "" &&
          filter.Propiedades === "" &&
          filter.Fecha_Creacion === "" &&
          filter.Provincia === "" &&
          filter.Direccion === "" &&
          filter.Alta !== true &&
          filter.fechaInicio === "" &&
          filter.fechaFin === ""
        )
      ) {
        filtrados = datos.filter(
          (comunidad) =>
            (filter.Id === "" ||
              (comunidad.Id &&
                normalizeString(comunidad.Id.toLowerCase()).includes(
                  normalizeString(filter.Id.toLowerCase())
                ))) &&
            (filter.Nombre === "" ||
              (comunidad.Nombre &&
                normalizeString(comunidad.Nombre.toLowerCase()).includes(
                  normalizeString(filter.Nombre.toLowerCase())
                ))) &&
            (filter.Cif === "" ||
              (comunidad.Cif &&
                normalizeString(comunidad.Cif.toLowerCase()).includes(
                  normalizeString(filter.Cif.toLowerCase())
                ))) &&
            (filter.Poblacion === "" ||
              (comunidad.Poblacion &&
                normalizeString(comunidad.Poblacion.toLowerCase()).includes(
                  normalizeString(filter.Poblacion.toLowerCase())
                ))) &&
            (filter.Cp === "" ||
              (comunidad.Cp && comunidad.Cp.toString().includes(filter.Cp.toString()))) &&
            (filter.Propiedades === "" ||
              (comunidad.Propiedades &&
                comunidad.Propiedades.toString().includes(filter.Propiedades))) &&
            (filter.Vecinos === "" ||
              (comunidad.Vecinos && comunidad.Vecinos.toString().includes(filter.Vecinos))) &&
            (filter.Fecha_Creacion === "" ||
              (comunidad.Fecha_Creacion &&
                normalizeString(comunidad.Fecha_Creacion.toLowerCase()).includes(
                  normalizeString(filter.Fecha_Creacion.toLowerCase())
                ))) &&
            (filter.Provincia === "" ||
              (comunidad.Provincia && compareWords(filter.Provincia, comunidad.Provincia))) &&
            (filter.Direccion === "" ||
              (comunidad.Direccion &&
                normalizeString(comunidad.Direccion.toLowerCase()).includes(
                  normalizeString(filter.Direccion.toLowerCase())
                ))) &&
            (filter.fechaInicio === "" ||
              (comunidad.fechaAlta && comunidad.fechaAlta >= formatFecha(filter.fechaInicio))) &&
            (filter.fechaFin === "" ||
              (comunidad.fechaAlta && comunidad.fechaAlta <= formatFecha(filter.fechaFin)))
        );
      } else {
        filtrados = datos;
      }

      setNumRtdos(filtrados.length);
      setComunidadeslista(filtrados);
      //}
    } catch (error) {
      console.log(error);
    }
  };

  function compareWords(searchTerm, actualValue) {
    // Function to normalize the string by removing special characters
    function normalizeAndCleanString(str) {
      return normalizeString(str.toLowerCase())
        .replace(/[^\w\s]/g, "") // Removes special characters
        .split(" ")
        .filter((word) => word) // Removes empty strings from spaces
        .sort(); // Sort words to handle any order
    }

    // Normalize and clean both the search term and actual value
    const searchWords = normalizeAndCleanString(searchTerm);
    const actualWords = normalizeAndCleanString(actualValue);

    // Check if every word in searchWords partially matches any word in actualWords
    return searchWords.every((searchWord) =>
      actualWords.some((actualWord) => actualWord.includes(searchWord))
    );
  }

  const obtenerCIFComunidadesDeBaja = () => {
    console.log("comunidadeslista", comunidadeslista);
    if (!comunidadeslista) return [];
    // Filtrar solo las comunidades de baja
    const comunidadesDeBaja =
      comunidadeslista && comunidadeslista.filter((comunidad) => !comunidad.Alta);

    // Obtener solo los CIF de las comunidades de baja
    const cifComunidadesDeBaja =
      comunidadesDeBaja && comunidadesDeBaja.map((comunidad) => comunidad.Cif);

    return cifComunidadesDeBaja;
  };
  /* boton que resetea los valores de los inputs y vuelve a mostrar todos los empleados */
  const resetObject = () => {
    setFilter(estadoInicialFiltros);
    setComunidadeslista(datosIniciales.current);
  };

  /* funciona para expandir y contraer el buscador */
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  /* funcion que se ejecuta cuando se cambia el valor de los inputs */

  function formatFecha(fecha) {
    if (fecha != "") {
      var components = fecha.split("-");
      var year = components[0].slice(-2); // Obtener los últimos dos dígitos del año
      var month = components[1];
      var day = components[2];

      // Formatear la fecha
      var formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    }
    return fecha;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };
  //// COSAS DEL FORM DE BUSCAR
  const modalEnvio = () => {
    setModalOpen(true);
  };

  const opcionestoolbar = {
    Toolbar: (props) => (
      <div className="toolbar-tabla">
        <Tooltip content={diccionario.tip_EnvioComunidades}>
          <span>
            <button
              onClick={() => modalEnvio()}
              variant="outlined"
              disabled={!comunidadesSeleccionadas.length > 0}
              size="large"
              color="primary"
              className="btn btn-outline-primary mt-3 px-4"
            >
              {diccionario.ENVIAR_AVISO}
            </button>
          </span>
        </Tooltip>
      </div>
    ),
  };

  const abrirDiagolo = () => {
    setshowDialogForm(true);
  };

  const cabeceraExportacion = [
    diccionario.Alta,
    diccionario.Nombre,
    /*"Id",*/
    diccionario.Cif,
    diccionario.Direccion,
    diccionario.Poblacion,
    diccionario.Provincia,
    diccionario.Cp,
    diccionario.Propiedades,
    diccionario.Vecinos,
    diccionario.Fecha_Creacion,
    diccionario.Fecha_Baja,
    diccionario.Bloqueada,
  ];
  const camposExportacion = [
    "Alta",
    "Nombre",
    /*"Id",*/
    "Cif",
    "Direccion",
    "Poblacion",
    "Provincia",
    "CP",
    "Propiedades",
    "Vecinos",
    "Fecha_Creacion",
    "Fecha_Baja",
    "bloqueada",
  ];
  const nombreExportacion = "Comunidades" + " " + new Date().toISOString().slice(0, 10);
  const tituloExportacion = "Comunidades";

  const tituloEnvioAvisos = diccionario.tituloFormAvisos;

  const [isModalOpen, setModalOpen] = useState(false);

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setFilter((prevFormulario) => ({
      ...prevFormulario,
      [name]: checked,
    }));
  };

  const resetFormContrato = () => {
    setPlan({
      plan: "",
      formapago: "",
      iban: "",
      nombre: Identificador,
      vecinos: "",
      tablaPrecios: null,
      precioSelected: null,
    });
  };

  if (loading) {
    return (
      <div>
        <ClipLoader
          size={100}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      </div>
    );
  }
  return (
    <div className="contenedor-comunidades">
      <Dialogo
        showDialog={showDialog}
        diagoloBody={cuerpoBloqueo}
        diagoloTitulo={tituloBloqueo}
        onConfirm={confirmarBloqueo}
        onClose={() => setShowDialog(false)}
      />
      <Dialogo
        showDialog={showDialogAlta}
        diagoloBody={safeReplace(diccionario.ConfirmarBajaComunidad, "${var1}", Identificador)}
        diagoloTitulo={"Dar de baja comunidad de C365"}
        onConfirm={handleBajaC365}
        onClose={() => setShowDialogAlta(false)}
      />

      <div className={`expansible-container ${expanded ? "expanded" : "contracted"}`}>
        <div className="header" onClick={toggleExpansion}>
          <p style={{ fontWeight: "500" }}>{diccionario.Buscador}</p>
          {expanded ? (
            <i className="fa fa-chevron-up" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          )}
        </div>
        <div className="container-formulario">
          <ValidatorForm onSubmit={handleFiltrarClick}>
            <Grid container spacing={3}>
              {/*<Grid className="Buscador" item lg={2} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Id}</label>
                </p>
                <TextField
                  value={filter.Id}
                  onChange={handleChange}
                  name="Id"
                  onKeyDown={handleKeyPress}
                />
          </Grid> */}
              <Grid className="Buscador" item lg={3} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Nombre}</label>
                </p>
                <TextField
                  value={filter.Nombre}
                  name="Nombre"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>

              <Grid className="Buscador" item lg={3} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Direccion}</label>
                </p>
                <TextField
                  value={filter.Direccion}
                  name="Direccion"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>

              <Grid className="Buscador" item lg={4} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.CIF}</label>
                </p>
                <TextField
                  value={filter.Cif}
                  name="Cif"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>

              <Grid className="Buscador" item lg={2} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Provincia}</label>
                </p>
                <TextField
                  value={filter.Provincia}
                  name="Provincia"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>

              <Grid className="Buscador" item lg={2} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Poblacion}</label>
                </p>
                <TextField
                  value={filter.Poblacion}
                  name="Poblacion"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>

              <Grid className="Buscador" item lg={2} md={4} sm={4} xs={4} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Cp}</label>
                </p>
                <TextField
                  value={filter.Cp}
                  name="Cp"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>
              <Grid className="Buscador" item lg={1} md={1} sm={1} xs={1} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Propiedades}</label>
                </p>
                <TextField
                  value={filter.Propiedades}
                  name="Propiedades"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>
              <Grid className="Buscador" item lg={1} md={1} sm={1} xs={1} sx={{ mt: 0 }}>
                <p>
                  <label>{diccionario.Vecinos}</label>
                </p>
                <TextField
                  value={filter.Vecinos}
                  name="Vecinos"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>
              {/*
                <Grid
                  className="Buscador"
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{ mt: 0 }}
                >
                  <p>
                    <label>{diccionario.Fecha_Creacion}</label>
                  </p>
                  <TextField
                    value={filter.fechaAlta}
                    name="fechaAlta"
            
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </Grid>
        */}
              <Grid className="Buscador" item lg={2} md={2} sm={2} xs={2} sx={{ mt: 0 }}>
                <Form.Group controlId="fechaInicio">
                  <Form.Label>{diccionario.AltaDesde}</Form.Label>
                  <Form.Control
                    name="fechaInicio"
                    type="date"
                    value={filter.fechaInicio}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </Form.Group>
              </Grid>

              <Grid className="Buscador" item lg={2} md={2} sm={2} xs={2} sx={{ mt: 0 }}>
                <Form.Group controlId="fechaFin">
                  <Form.Label>{diccionario.AltaHasta}</Form.Label>
                  <Form.Control
                    name="fechaFin"
                    type="date"
                    value={filter.fechaFin}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                </Form.Group>
              </Grid>
              <Grid
                className="boton-gordo"
                item
                lg={1}
                md={2}
                sm={2}
                xs={2}
                sx={{ mt: 0 }}
                style={{ marginTop: "0px" }}
              >
                <button
                  onClick={resetObject}
                  type="reset "
                  className="btn btn-outline-secondary mr-2 mt-3 px-4"
                  id="restaurar"
                >
                  {diccionario.Restaurar}
                </button>
                <button type="submit" className="btn btn-outline-primary mt-3 px-4">
                  {diccionario.Buscar}
                </button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>

      <div className="container-tabla" style={{ height: "auto", width: "auto" }}>
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const { page, sizePerPage, totalSize } = paginationProps;
            const startResult = (page - 1) * sizePerPage + 1;
            const endResult = Math.min(page * sizePerPage, totalSize);
            return (
              <div>
                <div className="toolbar-tabla-comunidades">
                  <div className="contenido-botones-comunidades">
                    <button
                      className="btn btn-outline-primary mx-2"
                      onClick={() =>
                        ExportToPDF(
                          comunidadeslista,
                          cabeceraExportacion,
                          camposExportacion,
                          nombreExportacion,
                          tituloExportacion
                        )
                      }
                    >
                      {diccionario.exportarPdf}
                    </button>
                    {ExportToCSV(
                      comunidadeslista,
                      cabeceraExportacion,
                      camposExportacion,
                      nombreExportacion,
                      tituloExportacion,
                      diccionario
                    )}

                    <RefreshButton onRefresh={handleFiltrarClick} />
                    <Tooltip content={diccionario.tip_EnvioComunidades}>
                      <span>
                        <button
                          onClick={() => modalEnvio()}
                          variant="outlined"
                          disabled={!comunidadesSeleccionadas.length > 0}
                          size="large"
                          color="primary"
                          className="btn btn-outline-primary mt-3 px-4"
                        >
                          {diccionario.ENVIAR_AVISO}
                        </button>
                      </span>
                    </Tooltip>
                    <BackButton />
                    <div className="pagination-container" style={{ display: "flex" }}>
                      <p>{diccionario.filasPagina} &nbsp;</p>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </div>
                  <div className="toolbar-envio-avisos">
                    <div className="comunidades-seleccionadas">
                      <div className="flechita">
                        <PiArrowElbowLeftDownBold />
                      </div>
                      <h5 style={{ marginTop: "5px", marginLeft: "5px" }}>
                        {comunidadesSeleccionadas.length === 0
                          ? ` ${diccionario.MarcarEnviarAviso}`
                          : `${diccionario.HasSeleccionado}${
                              comunidadesSeleccionadas.length === 1
                                ? ` 1 ${diccionario.comunidad}`
                                : ` ${comunidadesSeleccionadas.length} ${diccionario.comunidades}`
                            } ${diccionario.paraAviso}.`}
                      </h5>
                    </div>
                  </div>
                </div>
                <BootstrapTable
                  keyField="Cif"
                  data={comunidadeslista}
                  columns={columnas}
                  {...paginationTableProps}
                  rowEvents={tableRowEvents}
                  selectRow={{
                    mode: "checkbox",
                    clickToSelect: true,
                    nonSelectable: obtenerCIFComunidadesDeBaja(),
                    bgColor: "rgba(44, 169, 220, 0.1)",
                    onSelect: handleOnSelect,
                    onSelectAll: handleAllRowSelect,
                    selected: comunidadesSeleccionadas.map((comunidad) => comunidad.Cif),
                  }}
                />
                <div className="pagination-container-footer">
                  {totalSize > 0 ? (
                    <div className="data-info" style={{ fontWeight: "bold" }}>
                      {diccionario.Mostrando} {startResult} {diccionario.a} {endResult}{" "}
                      {diccionario.de} {totalSize} {diccionario.resultados}
                    </div>
                  ) : (
                    // Display a message when there are no matches
                    <div className="data-info" style={{ fontWeight: "bold" }}>
                      {diccionario.sinresultados}
                    </div>
                  )}
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            );
          }}
        </PaginationProvider>
        <ModalComponent
          top={"40%"}
          alturaCustom={"50%"}
          tituloModal={tituloEnvioAvisos}
          cuerpoModal={
            <FormularioEnvioAvisos
              comunidadesSeleccionadas={comunidadesSeleccionadas}
              abrirDiagolo={abrirDiagolo}
              cerrarDiagolo={() => setModalOpen(false)}
            />
          }
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          closeEnvios={true}
        />
        <ModalComponent
          planHistorico={planHistorico}
          setPlanHistorico={setPlanHistorico}
          step={step}
          setStep={setStep}
          alturaCustom={step < 4 ? "45%" : "600px"}
          anchuraCustom={"85%"}
          tituloModal={diccionario.ProcesoAltaComunidad}
          alturaContenido={step === 4 ? "500px" : ""}
          cuerpoModal={
            <Wizard
              Comunidad={Identificador}
              step={step}
              setStep={setStep}
              isModalOpen={isModalOpenAlta}
              setModalOpen={setModalOpen}
              plan={plan}
              setPlan={setPlan}
              resetFormContrato={resetFormContrato}
              refrescar={refrescar}
              setRefrescar={setRefrescar}
              datosCompletosComunidad={datosCompletosSeleccionados}
              planHistorico={planHistorico}
              esModificar={esModificar}
            />
          }
          isModalOpen={isModalOpenAlta}
          setModalOpen={setModalOpenAlta}
        />

        <ModalComponent
          setStep={setStep}
          tituloModal={diccionario.ProcesoAltaComunidad}
          anchuraCustom={"65%"}
          alturaCustom={"40%"}
          cuerpoModal={
            <StepContratado
              planHistorico={planHistorico}
              bajaFuncion={darDeBaja}
              modificarFuncion={handleModificarC365}
              plan={plan}
            />
          }
          isModalOpen={modalOpenContratado}
          setModalOpen={setModalOpenContratado}
        />
      </div>

      {FormularioEnvioAvisos}
    </div>
  );
};

export default ComunidadesTabla;
