import React from "react";
import { useStore } from "react-redux";

export const ContratoContenido = ({
  ContenidoPlan,
  ContenidoFormaPago,
  ContenidoIban,
  PrecioSelected,
}) => {
  const fechaActual = new Date().toLocaleString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  return (
    <div className="summary">
      <div className="summary-header">
        <h1>{diccionario.ResumenServicios}</h1>
        <p>{diccionario.Fecha}: {fechaActual}</p>
      </div>
  
      <div className="summary-body">
        <p>{diccionario.DetalleServicios}</p>
  
        <ul>
          <li>
            <strong>{diccionario.PlanSeleccionado}:</strong> {ContenidoPlan}
          </li>
          <li>
            <strong>{diccionario.FormaDePago}:</strong>{" "}
            {ContenidoFormaPago.charAt(0).toUpperCase() + ContenidoFormaPago.slice(1)}
          </li>
          <li>
            <strong>{diccionario.IbanAsociado}:</strong>{" "}
            {ContenidoIban.charAt(0).toUpperCase() + ContenidoIban.slice(1)}
          </li>
          <li>
            <strong>{diccionario.PrecioTotal}:</strong> {PrecioSelected} €
          </li>
        </ul>
  
        <p>{diccionario.SiDeseasModificar}</p>
      </div>
  
      <div className="summary-footer">
        <p>{diccionario.Atentamente},</p>
        <p>{diccionario.Empresa}</p>
      </div>
    </div>
  );
  /*
  return (
    <div className="summary">
      <div className="summary-header">
        <h1>Resumen de Servicios Contratados</h1>
        <p>Fecha: {fechaActual}</p>
      </div>

      <div className="summary-body">
        <p>A continuación, se detalla un resumen de los servicios contratados</p>

        <ul>
          <li>
            <strong>Plan seleccionado:</strong> {ContenidoPlan}
          </li>
          <li>
            <strong>Forma de pago:</strong>{" "}
            {ContenidoFormaPago.charAt(0).toUpperCase() + ContenidoFormaPago.slice(1)}
          </li>
          <li>
            <strong>IBAN asociado:</strong>{" "}
            {ContenidoIban.charAt(0).toUpperCase() + ContenidoIban.slice(1)}
          </li>
          <li>
            <strong>Precio total:</strong> {PrecioSelected} €
          </li>
        </ul>

        <p>
          Si realizar modificaciones, puedes retroceder en el proceso de contratación y volver al
          paso correspondiente. con nosotros.
        </p>
      </div>

      <div className="summary-footer">
        <p>Atentamente,</p>
        <p>C365</p>
      </div>
    </div>
  );
  */
};
