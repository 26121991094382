import ContratoComponent from "app/components/Contrato/ContratoComponent";
import { ContratoContenido } from "app/components/Contrato/ContratoContenido";
import { CurrencyFormatter } from "app/components/helpers/Views";
import React, { useEffect, useState } from "react";
import useAuth from "app/hooks/useAuth";
import { useStore } from "react-redux";

const circleBadgeStyle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#172983",
  color: "#fff",
  textAlign: "center",
  lineHeight: "30px",
};

export const Step3 = ({ onPrev, setPlan, plan, onNext, datosCompletosComunidad, planHistorico }) => {
  const [ibanOption, setIbanOption] = useState("");
  const { user } = useAuth();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [loading, setLoading] = useState(false);
  const datosDespacho = user.despacho;
  const ibanComunidad = datosCompletosComunidad.IBAN;

  const handlePaymentOptionChange = (event) => {
    setIbanOption(event.target.id);

    if (event.target.id == "comunidad") {
      setPlan({
        ...plan,
        iban: event.target.id,
        cuentaBancaria: ibanComunidad,
      });
    } else {
      setPlan({
        ...plan,
        iban: event.target.id,
        cuentaBancaria: datosDespacho.Iban,
      });
    }
  };

  useEffect(() => {
    if (planHistorico.iban != "" && planHistorico.iban == "administrador") {
      setIbanOption("administrador");
      setPlan({
        ...plan,
        iban: "administrador",
        cuentaBancaria: datosDespacho.Iban,
      });
    } else if (planHistorico.iban != "" && planHistorico.iban == "comunidad") {
      setIbanOption("comunidad");
      setPlan({
        ...plan,
        iban: "comunidad",
        cuentaBancaria: ibanComunidad,
      });
    }
  }, []);

  return (
    <div className="alta365-administradores">
      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <div style={circleBadgeStyle} className="mr-2">
            3
          </div>

          <h5 style={{ marginLeft: "15px", marginBottom: "0px" }}>{diccionario.SeleccionaOpcionFacturacion}:</h5>
        </div>
      </div>
      <hr />

      <div className="alta-iban">
        <div className="container mt-4">
          <div className="form-check">
            <input
              checked={ibanOption === "comunidad"}
              type="radio"
              className="form-check-input"
              id="comunidad"
              name="ibanOption"
              onChange={handlePaymentOptionChange}
            />
            <label className="form-check-label" htmlFor="ibanCommunity">
              {diccionario.IBANComunidad}: {ibanComunidad}
            </label>
          </div>

          <div className="form-check">
            <input
              checked={ibanOption === "administrador"}
              type="radio"
              className="form-check-input"
              id="administrador"
              name="ibanOption"
              onChange={handlePaymentOptionChange}
            />
            <label className="form-check-label" htmlFor="ibanAdmin">
              {diccionario.IBANAdministrador}: {datosDespacho.Iban}
            </label>
          </div>
          <br />
          <div className="precio-total">
            <div className="precio-total">
              <p>
                {diccionario.HasSeleccionadoPlan} <strong>{plan.plan}</strong> {diccionario.ConFormaPago}{" "}
                <strong>{plan.formapago}</strong>. {diccionario.ElPRecioTotal}: {CurrencyFormatter(plan.precioSelected)}{" "}
                {diccionario.al} <strong>{plan.formapago === "mensual" ? diccionario.mes : diccionario.año}</strong>
                {ibanOption && (
                  <>
                    {" "}
                    {diccionario.IBANElegido}: <strong>{plan.iban}</strong> {diccionario.cuentaBancariaEs}:{" "}
                    <strong> {plan.cuentaBancaria}</strong>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <i>
          <h5>* {diccionario.preciosSinIva}</h5>
        </i>
      </div>
      <div className="alta365-planes-botones">
        <button className="btn btn-outline-secondary px-4" onClick={onPrev}>
          {diccionario.Volver}
        </button>

        <button className="btn btn-outline-primary px-4" onClick={onNext} disabled={!ibanOption}>
          {diccionario.Siguiente}
        </button>
      </div>
    </div>
  );
};

export default Step3;
