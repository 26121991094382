import ContratoComponent from "app/components/Contrato/ContratoComponent";
import { ContratoContenido } from "app/components/Contrato/ContratoContenido";
import React, { useState } from "react";
import { useFirebase } from "app/firebase/context";
import { update, push } from "firebase/database";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { setRef } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useStore } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

const circleBadgeStyle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#172983",
  color: "#fff",
  textAlign: "center",
  lineHeight: "30px",
};

export const Step5 = ({
  onPrev,
  setPlan,
  plan,
  onNext,
  setStep,
  step,
  Comunidad,
  isModalOpen,
  setIsModalOpen,
  setModalOpen,
  resetFormContrato,
  setRefrescar,
  refrescar,
  datosCompletosComunidad,
}) => {
  const firebase = useFirebase();
  const [aceptarTerminos, setAceptarTerminos] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [loading, setLoading] = useState(false);
  const type = plan.iban === "administrador" ? "Despacho" : "Comunidad";
  const datosDespacho = user.despacho;
  const ibanComunidad = datosCompletosComunidad.IBAN;
  const ibanEfectivo = plan.iban === "administrador" ? datosDespacho.Iban : ibanComunidad;

  const handleCheckboxChange = () => {
    setAceptarTerminos(!aceptarTerminos);
  };
  const volverFirstStep = async () => {
    await handleEnviarDecision("Cancelar", Comunidad);
    setStep(1);
  };

  const handleEnviarDecision = async (decision, Comunidad) => {
    setLoading(true);
    try {
      // Actualizar el estado con la información obtenida
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString("es-ES", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      const formattedDateAlta = currentDate.toLocaleString("es-ES", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });

      const formattedDateStripe = currentDate
        .toLocaleString("es-ES", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");

      const updatedData = {
        Alta: true,
        fechaAlta: formattedDateAlta,
        ContratoActivo: {
          fechaInicio: formattedDate,
          fechaFin: null,
          formapago: plan.formapago,
          iban: plan.iban, // MIRAR SI HABRÍA QUE GUARDAR EL IBAN EN EL MOMENTO DE CONTRATAR (SEGURAMENTE SI, Y TENER EN CUENTA )
          plan: plan.plan,
          precioSelected: plan.precioSelected,
          vecinos: plan.vecinos,
          cuentaBancaria: plan.cuentaBancaria,
        },
      };

      const updatedData2 = {
        fechaInicio: formattedDate,
        fechaFin: null,
        formapago: plan.formapago,
        iban: plan.iban, // MIRAR SI HABRÍA QUE GUARDAR EL IBAN EN EL MOMENTO DE CONTRATAR (SEGURAMENTE SI, Y TENER EN CUENTA )
        plan: plan.plan,
        precioSelected: plan.precioSelected,
        vecinos: plan.vecinos,
        cuentaBancaria: plan.cuentaBancaria,
      };
      // Primero paso por stripe y si es correcto doy de alta y genero el contrato

      const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
      var confirmAccount = httpsCallable(functions, "confirmAccount");

      const deskId = user.despacho.Codigo;
      const communityId = Comunidad;
      const aceptada = decision === "Aceptar" ? true : false;

      confirmAccount({
        deskId: deskId,
        communityId: communityId,
        accepted: aceptada,
        type: type,
      })
        .then(async (res) => {
          console.log("decision", decision);
          if (res.data.status !== "success") {
            toast.error(diccionario.FalloPago, getCustomToastOptions(toast, "error"));
            throw diccionario.FalloPago;
          }
          if (decision === "Cancelar") {
            onPrev();
            setLoading(false);
            return;
          }
          const comUpdatear = firebase.getReferencia(`Comunidad/${Comunidad}`);
          const comUpdatear2 = firebase.getReferencia(`Comunidad/${Comunidad}/HistoricoContratos`);

          await update(comUpdatear, updatedData);
          await push(comUpdatear2, updatedData2);

          toast.success(diccionario.ContratoAceptadoPagosIniciados, getCustomToastOptions(toast));
          setRefrescar(!refrescar);
          setModalOpen(false);
          // RESETEAR EL FORM DE CONTRATACION
          navigate("/tutorial" + "/" + Comunidad);
          setLoading(false);
        })
        .catch((e) => {
          toast.error(diccionario.FalloEnElPago, getCustomToastOptions(toast, "error"));
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      toast.error(diccionario.FalloEnElPago, getCustomToastOptions(toast, "error"));
      console.error("Error al confirmar IBAN", error);
    }
  };

  return (
    <>
      <div style={{ display: loading === true ? "block" : "none" }}>
        <ClipLoader
          size={100}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      </div>
      <div style={{ display: loading !== true ? "block" : "none" }}>
        <div className="alta365-administradores" style={{ overflow: "auto !important" }}>
          <div className="container mt-4">
            <div className="d-flex align-items-center">
              <div style={circleBadgeStyle} className="mr-2">
                5
              </div>

              <h5 style={{ marginLeft: "15px", marginBottom: "0px" }}>
                {diccionario.ConfirmarIbar}
              </h5>
            </div>
          </div>
          <div class name="container mt-4" style={{ height: "300px" }}>
            <hr />
            <p>
              {diccionario.PrimeraVezPago} {type} {diccionario.ConEstaCuenta} {ibanEfectivo}.{" "}
              {diccionario.PulsaConfirmarPago}
            </p>
            {/*
            <button
              onClick={() => handleEnviarDecision("Cancelar", Comunidad)}
              className="btn btn-primary px-4"
            >
              {diccionario.cancelar}
            </button>
            &nbsp;&nbsp;
            <button
              onClick={() => handleEnviarDecision("Aceptar", Comunidad)}
              className="btn btn-primary px-4"
            >
              {diccionario.Confirmar}
            </button> */}
          </div>
        </div>

        <div className="row form-actions-fixed" id="footerAltac65" style={{ width: "95%" }}>
          <div style={{ width: "100%", display: "flex", gap: "20px" }}>
            <button
              className="btn btn-outline-secondary px-4"
              onClick={() => handleEnviarDecision("Cancelar", Comunidad)}
            >
              {diccionario.Volver}
            </button>
            <button
              className="btn btn-outline-secondary px-4"
              onClick={volverFirstStep}
              style={{ width: "140px" }}
            >
              {diccionario.VolverPrimerPaso}
            </button>
            <button
              style={{ marginLeft: "auto" }}
              onClick={() => handleEnviarDecision("Aceptar", Comunidad)}
              className="btn btn-outline-primary px-4"
            >
              {diccionario.Confirmar}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step5;
