import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useStore } from "react-redux";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import Switch from "react-switch";
import { set } from "lodash";
import { Motorcycle, SettingsInputAntennaTwoTone } from "@material-ui/icons";
export const Dialogo = ({
  onClose,
  showDialog,
  onConfirm,
  diagoloBody,
  diagoloTitulo,
  inputElement = false,
  inputValue,
  setInputValue,
  nombreCambiar = "",
  children = "",
  loading = false,
  tieneSwitch = false,
  tieneSwitch2 = false,
  tieneSwitch3 = false,
  enviarEmails = false,
  setEnviarEmails,
  enviarNotificaciones = false,
  setEnviarNotificaciones,
  checkedTransferible,
  setCheckedTransferible,
  tieneDescripcion = false,
  descripcion = "",
  setDescripcion,
  motivoCerrada,
  setMotivoCerrada,
  cambiosCriticos = false,
  tieneDescripcion2 = false,
  dialogoUnanimidad = false,
  descripcion2 = "",
}) => {
  const [vainavalue, setVainavalue] = useState("");
  const vainaloca = (e) => {
    setVainavalue(e.target.value);
  };

  const handleChangeDescripcion = (e) => {
    setMotivoCerrada(e.target.value);
  };

  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [showModal, setShowModal] = useState(true);
  const [cuentaAtras, setCuentaAtras] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setCuentaAtras(10);
    onClose(); // Aquí se cierra el modal en el componente padre
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const showBody = () => {
    if (children == "") {
      return diagoloBody;
    } else {
      return children;
    }
  };

  useEffect(() => {
    if (cambiosCriticos) {
      setIsButtonDisabled(true);
      if (cuentaAtras > 0) {
        const timer = setTimeout(() => setCuentaAtras(cuentaAtras - 1), 1000);
        return () => clearTimeout(timer);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [cuentaAtras]);

  useEffect(() => {
    if (cambiosCriticos) {
      setCuentaAtras(10);
    }
  }, [showDialog]);

  if (loading && showDialog) {
    return (
      <div>
        <Modal show={showDialog} onHide={handleClose} centered>
          <Modal.Body>
            <ClipLoader
              size={30}
              color={"#123abc"}
              loading={loading}
              speedMultiplier={1}
              cssOverride={{
                position: "relative",
                left: "48%",
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" disabled>
              {diccionario.cancelar}
            </Button>
            <Button variant="primary" disabled>
              {diccionario.aceptar}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  const toggleChecked = () => {
    setCheckedTransferible(!checkedTransferible);
  };
  const toggleChecked2 = () => {
    setEnviarEmails(!enviarEmails);
  };
  const toggleChecked3 = () => {
    setEnviarNotificaciones(!enviarNotificaciones);
  };
  /*
  console.log(
    "entro aqui en el dialogo el showDialog",
    showDialog,
    "el body",
    diagoloBody,
    "el titulo",
    diagoloTitulo,
    "el inputElement",
    inputElement,
    "el inputValue",
    inputValue,
    "el setInputValue",
    setInputValue,
    "el nombreCambiar",
    nombreCambiar,
    "los children",
    children,
    "el loading",
    loading,
    "tieneSwitch",
    tieneSwitch,
    "tieneSwitch2",
    tieneSwitch2,
    "tieneSwitch3",
    tieneSwitch3,
    "enviarEmails",
    enviarEmails,
    "setEnviarEmails",
    setEnviarEmails,
    "enviarNotificaciones",
    enviarNotificaciones,
    "setEnviarNotificaciones",
    setEnviarNotificaciones,
    "checkedTransferible",
    checkedTransferible,
    "setCheckedTransferible",
    setCheckedTransferible,
    "tieneDescripcion",
    tieneDescripcion,
    "descripcion",
    descripcion,
    "setDescripcion",
    setDescripcion,
    "motivoCerrada",
    motivoCerrada,
    "setMotivoCerrada",
    setMotivoCerrada,
    "cambiosCriticos",
    cambiosCriticos,
    "tieneDescripcion2",
    tieneDescripcion2,
    "dialogoUnanimidad",
    dialogoUnanimidad,
    "descripcion2",
    descripcion2
  );
  */
  return (
    <div>
      <Modal show={showDialog} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={cambiosCriticos === true ? { color: "red" } : {}}>
            {diagoloTitulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container spacing={3} className="datos-detalle" style={{ paddingTop: 13 }}>
            <Grid className="Buscador" item lg={10} md={10} sm={10} xs={10} sx={{ mt: 0 }}>
              {" "}
              {showBody()}{" "}
            </Grid>
            <Grid
              className="Buscador"
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
              sx={{ mt: 2 }} // Add margin-top here
            >
              {tieneSwitch && (
                <p>
                  <Switch
                    checked={checkedTransferible}
                    onChange={toggleChecked}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={10}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={28}
                  />{" "}
                  {checkedTransferible ? diccionario.Si : diccionario.No}
                </p>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {tieneSwitch2 && (
                <p>
                  {diccionario.enviarEmails}
                  {"   :  "}
                  <Switch
                    checked={enviarEmails}
                    onChange={toggleChecked2}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={10}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={28}
                  />{" "}
                  {enviarEmails ? diccionario.Si : diccionario.No}
                </p>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {tieneSwitch3 && (
                <p>
                  {diccionario.EnviarNotificaciones}
                  {"   :  "}
                  <Switch
                    checked={enviarNotificaciones}
                    onChange={toggleChecked3}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={10}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={28}
                  />{" "}
                  {enviarNotificaciones ? diccionario.Si : diccionario.No}
                </p>
              )}
            </Grid>
            {tieneDescripcion2 && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p>
                  <i>{descripcion2}</i>
                </p>
              </Grid>
            )}

            {tieneDescripcion && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label={"Motivo "}
                  multiline
                  rows={3}
                  defaultValue=""
                  variant="outlined"
                  value={motivoCerrada}
                  onChange={(e) => handleChangeDescripcion(e)}
                />{" "}
              </Grid>
            )}
          </Grid>
        </Modal.Body>
        <div style={{ textAlign: "center" }}>
          {inputElement && (
            <div
              style={{
                zIndex: 1000,
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "100px",
                marginRight: "100px",
                marginBottom: "20px",
              }}
            ></div>
          )}
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {dialogoUnanimidad ? diccionario.DeshacerCambios : diccionario.cancelar}
          </Button>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={(tieneDescripcion && motivoCerrada.length === 0) || isButtonDisabled}
          >
            {dialogoUnanimidad
              ? diccionario.Entendido
              : cambiosCriticos !== true
              ? diccionario.aceptar
              : cuentaAtras > 0
              ? diccionario.aceptar + " " + cuentaAtras
              : diccionario.aceptar}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dialogo;
