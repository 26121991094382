import React from "react";
import { useEffect } from "react";
import { useFirebase } from "../../../app/firebase/context";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "./../../constants/routes";
import { get } from "firebase/database";
import { toast } from "react-toastify";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { useStore } from "react-redux";
import useAuth from "app/hooks/useAuth";

export const useComprobarAcceso = ({ usuario, idComunidad }) => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const navigate = useNavigate();
  const firebase = useFirebase();
  const { user } = useAuth();

  useEffect(() => {
    if (idComunidad == false || user.SuperAdmin || !usuario) return;
    const getComunidadesUser = async () => {

      const snapshot5 = await get(firebase.getReferencia(`Usuarios/${usuario}`, null));
      const comunidades = [];
      snapshot5.val().Comunidades &&
        Object.keys(snapshot5.val().Comunidades).forEach((comunidad) => {
          if (comunidad !== "desk") {
            comunidades.push(comunidad);
          }
        });

      if (!comunidades.includes(idComunidad) && comunidades.length > 0) {
        toast.info(diccionario.NoTienesAcceso, getCustomToastOptions(toast, "info"));
        navigate(ROUTES.HOME);
      }
    };
    getComunidadesUser();
  }, []);
};
